/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
@font-face {
  font-family: 'Montserrat Alternates Bold';
  src: url('/fonts/Montserrat_Alternates/MontserratAlternates-Bold.ttf') format('truetype');
}
@font-face {
  font-family: 'Montserrat Alternates Black';
  src: url('/fonts/Montserrat_Alternates/MontserratAlternates-Black.ttf') format('truetype');
}
@font-face {
  font-family: 'Montserrat Medium';
  src: url('/fonts/Montserrat/static/Montserrat-Medium.ttf') format('truetype');
}
@font-face {
  font-family: 'Montserrat Bold';
  src: url('/fonts/Montserrat/static/Montserrat-Bold.ttf') format('truetype');
}
.font-montserrat-alt-bold {
  font-family: 'Montserrat Alternates Bold', sans-serif;
}
.font-montserrat-alt-black {
  font-family: 'Montserrat Alternates Black', sans-serif;
}
.font-montserrat-medium {
  font-family: 'Montserrat Medium', sans-serif;
}
.font-montserrat-bold {
  font-family: 'Montserrat Bold', sans-serif;
}
.ant-btn.ant-btn-primary.btn-red {
  color: #fff;
  background: #d82a32;
  border-color: #d82a32;
}
.ant-btn.ant-btn-primary.btn-gray {
  color: #000;
  background: #d9d9d9;
  border-color: #d9d9d9;
}
.ant-btn.ant-btn-default.btn-red {
  color: #d82a32;
  background: #fff;
  border-color: #d82a32;
}
.ant-btn.ant-btn-default.btn-red:hover {
  border-color: #d82a32;
  background-color: #d82a32;
  color: #fff;
}
.ant-btn.ant-btn-default.btn-gray {
  color: #000;
  background: #fff;
  border-color: #d9d9d9;
}
.ant-btn.ant-btn-default.btn-gray:hover {
  border-color: #9e3226;
  background-color: #fff;
  color: #9e3226;
}
.ant-table .ant-table-container .ant-table-content {
  overflow: auto;
  max-height: 80vh;
}
.ant-table .ant-table-container .ant-table-content .ant-table-thead .ant-table-column-title {
  font-weight: bold;
  text-transform: capitalize;
}
.ant-table .ant-table-container .ant-table-content .ant-table-thead .anticon-caret-up.ant-table-column-sorter-up {
  content: url("/images/table/table_sorting_up.svg");
  display: none;
  transform: scale(0.8);
  margin-top: -0.3em;
}
.ant-table .ant-table-container .ant-table-content .ant-table-thead .anticon-caret-up.ant-table-column-sorter-up.active {
  display: block;
}
.ant-table .ant-table-container .ant-table-content .ant-table-thead .anticon-caret-down.ant-table-column-sorter-down {
  content: url("/images/table/table_sorting_down.svg");
  display: none;
  transform: scale(0.8);
}
.ant-table .ant-table-container .ant-table-content .ant-table-thead .anticon-caret-down.ant-table-column-sorter-down.active {
  display: block;
}
.ant-table .ant-table-container .ant-table-content .ant-table-tbody tr.ant-table-row:nth-of-type(2n+1) td {
  background-color: #f8f8f8;
}
.ant-table .ant-table-container .ant-table-content .ant-table-tbody tr.ant-table-row:nth-of-type(2n+1):hover td {
  background-color: #ffeaec;
}
.ant-table .ant-table-container .ant-table-content .ant-table-tbody tr.ant-table-row:nth-of-type(2n+1).ant-table-row-selected td {
  background-color: #fffbdb;
}
.ant-table .ant-table-container .ant-table-content .ant-table-tbody tr.ant-table-row .anticon-edit {
  font-size: 1.2rem;
  cursor: pointer;
}
.ant-table .ant-table-container .ant-table-content .ant-table-tbody tr.ant-table-row .anticon-search {
  font-size: 1rem;
}
.ant-table .ant-table-container .ant-table-content .ant-table-tbody tr.ant-table-row .anticon-setting {
  font-size: 1rem;
}
.ant-table .ant-table-container .ant-table-content .ant-table-tbody tr.ant-table-row .ant-table-expand-icon-col {
  width: inherit;
  min-width: 50px;
}
.ant-table .ant-table-container .ant-table-content .ant-table-cell.ant-table-row-expand-icon-cell {
  display: none;
}
.ant-table .ant-table-expanded-row .table {
  border: 1px dashed #b5b5b5;
}
.ant-table .ant-table-expanded-row h1 {
  font-weight: bold;
  text-decoration: underline;
}
@media screen and (max-width: 767px) {
  .ant-table .ant-table-expanded-row td .language-icon:hover {
    padding: 10px 0 10px 10px !important;
  }
}
.ant-pagination .ant-pagination-item {
  margin-right: 5px !important;
  height: 22px !important;
  border-radius: 80px;
  border: none;
}
.ant-pagination .ant-pagination-item a:hover {
  border-radius: 80px;
  border: none;
  color: #fff;
  background-color: #feb607;
}
.ant-pagination .ant-pagination-item.ant-pagination-item-active a {
  color: #fff;
}
.ant-layout-header {
  padding: 0 50px;
}
@media screen and (max-width: 767px) {
  .ant-layout-header {
    padding: 0 5px;
    line-height: 50px !important;
  }
}
.ant-menu-light .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open:not(.ant-menu-submenu-selected) {
  color: black;
}
.ant-input-number {
  width: 100%;
}
.ant-picker-ok {
  display: none !important;
}
.fieldRemark .ant-row.ant-form-item {
  margin-bottom: 0px;
}
.removeColon .ant-form-item-label > label::after {
  content: '';
}
.custom-pagination {
  float: right;
  margin: 10px;
}
.custom-pagination .ant-pagination .ant-pagination-item {
  height: 30px !important;
}
.language-icon {
  font-size: 26px;
  color: #d82a32 !important;
}
.language-icon:hover {
  color: #9e3226 !important;
}
@media screen and (max-width: 767px) {
  .language-icon .language-icon:hover {
    font-size: 20px;
  }
}
.section-row {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
}
.feature-title {
  margin: 30px auto 0;
  background: #ffffff;
  font-weight: bold;
  font-size: 22px;
  font-family: 'Montserrat Alternates Black', sans-serif;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  text-transform: uppercase;
}
.card-title {
  color: #ffffff;
  font-family: 'Montserrat Bold', sans-serif;
  font-size: 14px;
  text-align: center;
  margin-top: 15px;
}
.partner-title {
  padding-top: 80px;
  background: #ffffff;
  font-weight: bold;
  font-size: 22px;
  font-family: 'Montserrat Alternates Black', sans-serif;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  text-transform: uppercase;
  width: 100%;
}
.partner-content {
  color: #ffffff;
  font-size: 16px;
  font-family: 'Montserrat Medium', sans-serif;
}
.content-join-us-text {
  font-family: 'Montserrat Medium', sans-serif;
  max-width: 650px;
}
.content-footer-title {
  font-family: 'Montserrat Medium', sans-serif;
}
.content-footer-text {
  font-family: 'Montserrat Medium', sans-serif;
}
.MenuBtn {
  font-weight: 900;
  font-family: 'Montserrat Alternates Bold', sans-serif;
}
.content-join-us-content {
  position: absolute;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100%;
  width: 100%;
}
.langTest {
  box-shadow: none !important;
  border: 1px solid #ddc08d;
  border-radius: 20px !important;
}
.mainMenuTxt {
  font-weight: bold;
  color: black !important;
}
.CSImageWrapper {
  width: 35px;
  height: 90px;
  text-align: center;
  position: fixed;
  right: 15px;
  bottom: 20px;
  z-index: 999 !important;
  cursor: pointer;
  background-color: #9e3226;
  border-radius: 35px;
}
.CSImage {
  margin-top: 5px;
  padding: 0;
  width: 32px;
  height: 32px;
}
.hour {
  margin-top: 10px;
  padding: 0;
  width: 32px;
  height: 32px;
}
.top-bar {
  background: rgba(255, 255, 255, 0.8);
  position: fixed;
  z-index: 11;
  width: 100%;
  height: 64px;
}
@media screen and (max-width: 767px) {
  .top-bar {
    background: rgba(255, 255, 255, 0.8);
    position: fixed;
    z-index: 11;
    width: 100%;
    height: 50px;
  }
}
.left-menu-wrapper {
  float: left;
}
@media screen and (max-width: 767px) {
  .left-menu-wrapper {
    display: none;
  }
}
.left-menu {
  background-color: rgba(255, 255, 255, 0);
  height: 64px;
}
.lang-container {
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  padding: 12px;
}
@media screen and (max-width: 767px) {
  .lang-container .lang-container {
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    vertical-align: middle;
    padding: 2px;
  }
}
.content-one-play {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  margin-top: 20px;
  cursor: pointer;
}
.content-one-play .play-more {
  width: 120px;
}
.content-one-play .play-btn {
  margin-left: 20px;
  width: 40px;
}
@media screen and (max-width: 767px) {
  .content-one-play {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    cursor: pointer;
  }
  .content-one-play .play-more {
    width: 120px;
  }
  .content-one-play .play-btn {
    margin-top: 10px;
    width: 40px;
    margin-bottom: 10px;
  }
}
.feature-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  background-image: url('/images/landingPage/feature_bg.jpg');
  background-size: cover;
  background-position: center;
}
@media screen and (max-width: 767px) {
  .feature-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-image: url('/images/landingPage/feature_bg_mobile.jpg');
    background-size: cover;
  }
}
.feature-content-inner {
  padding: 40px 0;
}
.section-row {
  margin: auto;
  max-width: 800px;
}
.feature-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px 50px;
  width: 33%;
}
@media screen and (max-width: 767px) {
  .feature-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px 20px;
    width: 50%;
  }
}
.feature-image {
  width: 120px;
  height: 120px;
}
@media screen and (max-width: 767px) {
  .feature-image {
    width: 75px;
    height: 75px;
  }
}
.content-join-us {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  background-image: url('/images/landingPage/star_bg.jpg');
  background-size: cover;
  background-position: center;
  height: 44vw;
  max-height: 500px;
}
@media screen and (max-width: 767px) {
  .content-join-us {
    width: 100%;
    text-align: center;
    position: relative;
    background-image: url('/images/landingPage/star_bg_mobile.jpg');
    background-size: cover;
    padding-bottom: 91%;
  }
}
.content-join-us-text {
  padding: 20px 20px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 16px;
}
@media screen and (max-width: 767px) {
  .content-join-us-text {
    padding: 20px 20px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 16px;
  }
}
.content-footer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 90px;
  margin-top: -90px;
  background-color: rgba(255, 255, 255, 0.5);
  position: absolute;
  padding-bottom: 10px;
}
@media screen and (max-width: 767px) {
  .content-footer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 45px;
    margin-top: -45px;
    background-color: rgba(255, 255, 255, 0.5);
    position: absolute;
    padding-bottom: 10px;
  }
}
.content-footer-title {
  margin-top: 10px;
  font-size: 14px;
  font-weight: bold;
}
@media screen and (max-width: 767px) {
  .content-footer-title {
    margin-top: 0;
    font-size: 10px;
    font-weight: bold;
  }
}
.content-footer-text {
  font-size: 14px;
  color: #e60000;
}
@media screen and (max-width: 767px) {
  .content-footer-text {
    font-size: 10px;
    color: #e60000;
  }
}
.index {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  overflow-y: scroll;
  background-image: url('/images/index-background.jpg');
  background-size: cover;
}
@media (max-width: 576px) {
  .index {
    background-image: url('/images/index-background.jpg');
    background-size: cover;
  }
}
.index .ant-dropdown-trigger svg {
  color: #b88422;
}
.index .ant-layout {
  background: transparent;
}
.index footer {
  color: white;
}
.index .ant-input,
.index .ant-input-password {
  background: transparent !important;
  border-color: #464646;
  font-weight: 600;
  letter-spacing: 2px;
  text-indent: 10px;
}
.index .ant-input::placeholder,
.index .ant-input-password::placeholder {
  color: #464646;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 2px;
  text-indent: 10px;
}

/* inter-cyrillic-ext-400-normal*/
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url(/_next/static/media/inter-cyrillic-ext-400-normal.55d11fdd.woff2) format('woff2'), url(/_next/static/media/inter-all-400-normal.f8403e6f.woff) format('woff');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* inter-cyrillic-400-normal*/
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url(/_next/static/media/inter-cyrillic-400-normal.995ae028.woff2) format('woff2'), url(/_next/static/media/inter-all-400-normal.f8403e6f.woff) format('woff');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* inter-greek-ext-400-normal*/
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url(/_next/static/media/inter-greek-ext-400-normal.601c8483.woff2) format('woff2'), url(/_next/static/media/inter-all-400-normal.f8403e6f.woff) format('woff');
  unicode-range: U+1F00-1FFF;
}
/* inter-greek-400-normal*/
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url(/_next/static/media/inter-greek-400-normal.a64c5bf6.woff2) format('woff2'), url(/_next/static/media/inter-all-400-normal.f8403e6f.woff) format('woff');
  unicode-range: U+0370-03FF;
}
/* inter-vietnamese-400-normal*/
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url(/_next/static/media/inter-vietnamese-400-normal.d9a42247.woff2) format('woff2'), url(/_next/static/media/inter-all-400-normal.f8403e6f.woff) format('woff');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* inter-latin-ext-400-normal*/
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url(/_next/static/media/inter-latin-ext-400-normal.5593dfa8.woff2) format('woff2'), url(/_next/static/media/inter-all-400-normal.f8403e6f.woff) format('woff');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* inter-latin-400-normal*/
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url(/_next/static/media/inter-latin-400-normal.ef09701c.woff2) format('woff2'), url(/_next/static/media/inter-all-400-normal.f8403e6f.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* inter-cyrillic-ext-700-normal*/
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url(/_next/static/media/inter-cyrillic-ext-700-normal.8a1d56d2.woff2) format('woff2'), url(/_next/static/media/inter-all-700-normal.af2e18a6.woff) format('woff');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* inter-cyrillic-700-normal*/
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url(/_next/static/media/inter-cyrillic-700-normal.e3e71337.woff2) format('woff2'), url(/_next/static/media/inter-all-700-normal.af2e18a6.woff) format('woff');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* inter-greek-ext-700-normal*/
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url(/_next/static/media/inter-greek-ext-700-normal.8acbf312.woff2) format('woff2'), url(/_next/static/media/inter-all-700-normal.af2e18a6.woff) format('woff');
  unicode-range: U+1F00-1FFF;
}
/* inter-greek-700-normal*/
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url(/_next/static/media/inter-greek-700-normal.ca54f55e.woff2) format('woff2'), url(/_next/static/media/inter-all-700-normal.af2e18a6.woff) format('woff');
  unicode-range: U+0370-03FF;
}
/* inter-vietnamese-700-normal*/
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url(/_next/static/media/inter-vietnamese-700-normal.a20d80ae.woff2) format('woff2'), url(/_next/static/media/inter-all-700-normal.af2e18a6.woff) format('woff');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* inter-latin-ext-700-normal*/
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url(/_next/static/media/inter-latin-ext-700-normal.f9cce6d1.woff2) format('woff2'), url(/_next/static/media/inter-all-700-normal.af2e18a6.woff) format('woff');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* inter-latin-700-normal*/
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url(/_next/static/media/inter-latin-700-normal.29ddd561.woff2) format('woff2'), url(/_next/static/media/inter-all-700-normal.af2e18a6.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* inter-cyrillic-variable-wghtOnly-normal */
@font-face {
  font-family: 'InterVariable';
  font-style: normal;
  font-display: swap;
  font-weight: 100 900;
  src: url(/_next/static/media/inter-cyrillic-variable-wghtOnly-normal.dbe77dd3.woff2) format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}  
/* inter-cyrillic-ext-variable-wghtOnly-normal */
@font-face {
  font-family: 'InterVariable';
  font-style: normal;
  font-display: swap;
  font-weight: 100 900;
  src: url(/_next/static/media/inter-cyrillic-ext-variable-wghtOnly-normal.e8551839.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}  
/* inter-greek-variable-wghtOnly-normal */
@font-face {
  font-family: 'InterVariable';
  font-style: normal;
  font-display: swap;
  font-weight: 100 900;
  src: url(/_next/static/media/inter-greek-variable-wghtOnly-normal.65e5eb2c.woff2) format('woff2');
  unicode-range: U+0370-03FF;
}  
/* inter-greek-ext-variable-wghtOnly-normal */
@font-face {
  font-family: 'InterVariable';
  font-style: normal;
  font-display: swap;
  font-weight: 100 900;
  src: url(/_next/static/media/inter-greek-ext-variable-wghtOnly-normal.118604ae.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}  
/* inter-latin-variable-wghtOnly-normal */
@font-face {
  font-family: 'InterVariable';
  font-style: normal;
  font-display: swap;
  font-weight: 100 900;
  src: url(/_next/static/media/inter-latin-variable-wghtOnly-normal.13e4ac98.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}  
/* inter-latin-ext-variable-wghtOnly-normal */
@font-face {
  font-family: 'InterVariable';
  font-style: normal;
  font-display: swap;
  font-weight: 100 900;
  src: url(/_next/static/media/inter-latin-ext-variable-wghtOnly-normal.8b2e784d.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}  
/* inter-vietnamese-variable-wghtOnly-normal */
@font-face {
  font-family: 'InterVariable';
  font-style: normal;
  font-display: swap;
  font-weight: 100 900;
  src: url(/_next/static/media/inter-vietnamese-variable-wghtOnly-normal.295fdab9.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}  

:root {
  --primaryColor: #9e3226;

  --commonBorderColor: rgb(227, 227, 227);
}

.ant-btn.ant-btn-primary {
  background: #9e3226;
  border-color: #9e3226;
}
.ant-btn.ant-btn-primary:hover {
  background: #9e3226;
  border-color: #9e3226;
}
.ant-btn.ant-btn-primary:focus {
  background: #9e3226;
  border-color: #9e3226;
}
.ant-btn.ant-btn-primary:active {
  background: #9e3226;
  border-color: #9e3226;
}

