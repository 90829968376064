:root {
  --primaryColor: #9e3226;

  --commonBorderColor: rgb(227, 227, 227);
}

.ant-btn.ant-btn-primary {
  background: #9e3226;
  border-color: #9e3226;
}
.ant-btn.ant-btn-primary:hover {
  background: #9e3226;
  border-color: #9e3226;
}
.ant-btn.ant-btn-primary:focus {
  background: #9e3226;
  border-color: #9e3226;
}
.ant-btn.ant-btn-primary:active {
  background: #9e3226;
  border-color: #9e3226;
}
